<template>
  <div>
    <a-button @click="sortSteps">排序</a-button>
    <a-steps :current="100" direction="vertical">
      <a-popover slot="progressDot" slot-scope="{ index, status, prefixCls }">
        <template slot="content">
          <span>step {{ index }} status: {{ status }}</span>
        </template>
        <span :class="`${prefixCls}-icon-dot`" />
      </a-popover>
      <a-step
        v-for="(item, index) in steps"
        :key="index"
        :title="item.time"
        :description="item.title"
      ></a-step>
    </a-steps>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // current: 0,
      steps: [
        {
          title: "张三上报了[]事件",
          time: "2024-01-02 14:35:58",
        },
        {
          title: "步骤二",
          time: "2024-01-02 14:38:58",
        },
        {
          title: "步骤三",
          time: "2024-01-02 14:40:58",
        },
      ],
    };
  },
  methods: {
    sortSteps() {
      // 根据 isAscending 变量决定排序顺序
      this.steps.sort((a, b) => {
        const timeA = this.parseTimeToMinutes(a.time);
        const timeB = this.parseTimeToMinutes(b.time);
        // 如果 isAscending 为 true，则升序排序；否则降序排序
        return this.isAscending ? timeA - timeB : timeB - timeA;
      });

      // 切换排序顺序
      this.isAscending = !this.isAscending;

      // 重置当前步骤到第一个
      this.current = 0;

      console.log(this.steps);
    },

    // 将时间字符串转换为分钟数
    parseTimeToMinutes(timeString) {
      const dateTime = new Date(timeString);
      const startOfDay = new Date(
        dateTime.getUTCFullYear(),
        dateTime.getUTCMonth(),
        dateTime.getUTCDate()
      );
      const diffInMs = dateTime - startOfDay;
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60)); // 将毫秒转换为分钟
      return diffInMinutes;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-steps-dot .ant-steps-item-content {
  width: 20%;
}
</style>